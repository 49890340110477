import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Router, RouterModule, Routes} from "@angular/router";
import {CreateTokenComponent} from "./create-token/create-token.component";
import {SendTokenComponent} from "./send-token/send-token.component";


const routes: Routes = [
  { path: 'create-token/:id', component: CreateTokenComponent },
  { path: 'send-token/:id', component: SendTokenComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];
@NgModule({
  declarations: [
    CreateTokenComponent,
    SendTokenComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

  ],
  exports:[
    RouterModule
  ],
  providers:[

  ]
})
export class ApiModule {}
