import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {Router} from '@angular/router'
import {CilSmartContractTransactionModel} from "../../components/transaction.model";
import {BehaviorSubject} from "rxjs";
import {environment} from "../../../environments/environment";
import {
  AdminTokenResult,
  ApiClient,
  IWalletListUnspentQueryResult, NotCompletedTokensResult,
  TransactionViewModel,
  WalletCoinType, WalletListUnspentQueryResult
} from "../../components/Api";
import {CilTransactionHelper} from "../../components/CilTransaction";
import {WalletDto} from "../../components/WalletDto";
import * as timers from "timers";
import {CilWalletDto} from "../../components/CilWalletDto";


@Component({
    template: `<div *ngIf="isOK !== null" id="response_data">
        {{isOK}}
    </div>
    `,
    selector: 'create-token'
  }
)
export class CreateTokenComponent implements OnInit {

  data: NotCompletedTokensResult | null = null;
  wallet: WalletDto | null = null;
  unspents: WalletListUnspentQueryResult | null = null;


  id: string | null = '';
  isOK: boolean | null = null;
  cilSmartContractTransactionModel = new BehaviorSubject<CilSmartContractTransactionModel | null>(null);

  constructor(
    protected api: ApiClient,
    private _route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    console.log('create-token-1');

    this._route.paramMap.subscribe((params: ParamMap) =>  {
      this.id = params.get('id')

      if (this.id != null) {
        // request for token info
        this.api.GetNotCompletedTokens(this.id).subscribe((data) => {

          if (data !== null && data.length === 1) {
            this.data = data[0];
            console.log(this.data);

            if (this.data.id == this.id && this.data.stage == 2) { // check id and stage
              // token data receive
              // create token
              this.cilSmartContractTransactionModel.next({
                amount: environment.cilTokenRequiredAmount,
                walletName: 'CIL',
                coinType: WalletCoinType.Cil,
                addressFrom: environment.cilTokenProofAddress,
                fee: 0, //number
                contractAddress: environment.cilTokenContractAddress, //string
                smartContractId: environment.cilTokenSmartContractId, //number
                consiliumId: environment.cilTokenConsiliumId, //number
                method: "createToken", //string
                params: [
                  {name: "strSymbol", value: this.data.symbol},
                  {name: "nTotalSupply", value: Number(this.data.emissionAmount)},
                  {name: "strIssuerName", value: "4Tokens"},
                  {name: "strGoals", value: this.data.description},
                  {name: "decimals", value: Number(this.data.decimals)},
                ] //MethodParam[]
              });

              const transactionParams = this.cilSmartContractTransactionModel.value!.params.reduce(function(map, obj) {
                // @ts-ignore
                map[obj.name] = obj.value;
                return map;
              }, {});

              //receive unspents
              this.api.getWalletListUnspent("Ux" + environment.cilTokenProofAddress).subscribe( _unspent => {

                if (_unspent !== null && _unspent !== undefined) {
                  this.unspents = _unspent;

                  console.log(this.unspents);

                  //make wallet
                  try {
                    this.wallet = new CilWalletDto(environment.cilTokenProof, null);

                    console.log(this.wallet);

                    if (this.wallet.address === environment.cilTokenProofAddress) {

                      //the same address
                      const cilSmartContractTran = CilTransactionHelper.getCilSmartContractTran(
                        this.wallet!, environment.cilTokenContractAddress, environment.cilTokenConsiliumId, environment.cilTokenRequiredAmount,
                        this.unspents!, 'createToken', [transactionParams]);
                      const request = new TransactionViewModel({
                        transaction: cilSmartContractTran.encode().toString('hex'),
                      });

                      const token = new AdminTokenResult({
                        symbol: this.data!.symbol,
                        ticker: this.data!.ticker,
                        name: this.data!.name,
                        description: this.data!.description,
                        decimals: this.data!.decimals,
                        contractAddress: environment.cilTokenContractAddress,
                        emissionAmount: this.data!.emissionAmount,
                        emissionAddress: environment.cilTokenProofAddress,
                        logo: this.data!.logo,
                        facebook: this.data!.facebook,
                        twitter: this.data!.twitter,
                        telegram: this.data!.telegram,
                        tags: this.data!.tags,
                        id: undefined,
                        status: undefined,
                        sourceCode: undefined,
                        userAddress: this.data!.userAddress,
                        stage: this.data!.stage,
                        complete: this.data!.complete
                      })


                      this.api.sendTransactionCreateToken(request, token).subscribe(() => {
                        //SUCCESS

                        this.isOK = true; //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1

                      }, (error) => {
                        this.isOK = false;
                      });

                    } else {
                      this.isOK = false;
                    }
                  } catch (e) {
                    this.isOK = false;
                  }

                } else {
                  this.isOK = false;
                }

              }, (error) => {
                this.isOK = false;
              });


            } else {
              this.isOK = false;
            }
          }
          else {
            this.isOK = false;
          }

        }, (error) => {
          this.isOK = false;
        });
      }
      else {
        //No id
        this.isOK = false;
      }
    });

    console.log('create-token-2');




  }



  protected readonly JSON = JSON;
}
