
import {CryptoLib} from './crypto';
import {ec} from 'elliptic';

const ecVal = new ec('secp256k1');

export class KeyPair {

  _pair: any;

  constructor(keyPair: any) {
    this._pair = keyPair;
  }

  /**
   *
   * @return {KeyPair}
   */
  static createKeyPair() {
    return new KeyPair(ecVal.genKeyPair());
  }

  /**
   *
   * @return {KeyPair}
   */
  static keyPairFromPrivate(privateKey: any, enc = 'hex') {
    return new KeyPair(ecVal.keyPair({priv: privateKey, privEnc: enc}));
  }

  /**
   *
   * @return {KeyPair}
   */
  static keyPairFromPublic(publicKey: any, enc = 'hex') {
    return new KeyPair(ecVal.keyFromPublic(publicKey, enc));
  }

  get address() {
    return this.getAddress(false);
  }

  get privateKey() {
    return this.getPrivate();
  }

  get publicKey() {
    return this.getPublic();
  }

  /**
   * if you need point - pass false to encoding
   *
   * @param {String | *} encoding
   * @return {*}
   */
  getPrivate(encoding = 'hex') {
    return this._pair.getPrivate(encoding);
  }

  /**
   * if you need point - pass false to encoding
   *
   * @param {Boolean} compact
   * @param {String | *} encoding
   * @return {*}
   */
  getPublic(compact = true, encoding = 'hex') {
    return this._pair.getPublic(compact, encoding);
  }

  /**
   *
   * @param {Boolean} needBuffer
   * @returns {String|Buffer}
   */
  getAddress(needBuffer = true) {
    return CryptoLib.getAddress(this.getPublic(), needBuffer);
  }
}
