/* tslint:disable:no-unnecessary-initializer */
const cryptojs = require('crypto-js');
const scrypt = require('scrypt-js');
const sha3 = require('js-sha3');
const utf8 = require('ethers/utils/utf8');
const bytes = require('ethers/utils/bytes');
const BN = require('bn.js');

import {Buffer} from 'buffer';
import {ec} from 'elliptic';

const ecVal = new ec('secp256k1');

const keySize = 256;
const LENGTH = 16;
const SCRYPT_OPTIONS = {N: 131072, r: 8, p: 1};
const PBKDF2_OPTIONS = {iterations: 1000};

export class CryptoLib {

  // @ts-ignore
  /**
   *
   * @param {Buffer} msg
   * @param {BN|String} key - private key (BN - BigNumber @see https://github.com/indutny/bn.js)
   * @param {String} enc - encoding of private key. possible value = 'hex', else it's trated as Buffer
   * @param {Object} options - for hmac-drbg
   * @return {Buffer}
   */
  // @ts-ignore
  static sign(msg, key, enc = 'hex', options = undefined) {
    if (!key) {
      throw new Error('Bad private key!');
    }
    const sig = ecVal.sign(msg, key, enc, options);
    return CryptoLib.signatureToBuffer(sig);
  }

  /**
   *
   * @param {Object} signature
   * @param {BN} signature.r
   * @param {BN} signature.s
   * @param {Number} signature.recoveryParam
   * @return {Buffer}
   */
  // @ts-ignore
  static signatureToBuffer(signature) {
    if (!signature || !signature.r || !signature.s || signature.recoveryParam === undefined) {
      throw new Error('Bad signature!');
    }
    const buffR = Buffer.from(signature.r.toArray('bn', 32));
    const buffS = Buffer.from(signature.s.toArray('bn', 32));
    return Buffer.concat([buffR, buffS, Buffer.from([signature.recoveryParam])]);
  }

  /**
   *
   * @param {Buffer} buff
   * @return {Object} {r,s, recoveryParam}
   */
  // @ts-ignore
  static signatureFromBuffer(buff) {
    if (buff.length !== 65) {
      throw new Error(`Wrong signature length: ${buff.length}`);
    }
    const buffR = buff.slice(0, 32);
    const buffS = buff.slice(32, 64);
    const buffRecovery = buff.slice(64, 65);
    return {
      r: new BN(buffR.toString('hex'), 16, 'be'),
      s: new BN(buffS.toString('hex'), 16, 'be'),
      recoveryParam: buffRecovery[0]
    };
  }

  /**
   *
   * @param {Buffer} msg
   * @return {String}
   */
  // @ts-ignore
  static createHash(msg) {
    return CryptoLib.sha3(msg);
  }

  // @ts-ignore
  /**
   * Same as above, but returns Buffer
   *
   * @param {Buffer} msg
   * @return {Buffer}
   */
  // @ts-ignore
  static createHashBuffer(msg) {
    return Buffer.from(CryptoLib.createHash(msg), 'hex');
  }

  /**
   * Get public key from signature
   * ATTENTION! due "new BN(msg)" (@see below) msg.length should be less than 256bit!!
   * So it's advisable to sign hashes!
   *
   * @param {Buffer} msg
   * @param {Object | Buffer} signature @see elliptic/ec/signature
   * @return {String} compact public key
   */
  // @ts-ignore
  static recoverPubKey(msg: string, signature) {
    const sig = Buffer.isBuffer(signature) ? CryptoLib.signatureFromBuffer(signature) : signature;

    // @see node_modules/elliptic/lib/elliptic/ec/index.js:198
    // "new BN(msg);" - no base used, so we convert it to dec

    // @ts-ignore we dont have BN typing
    const hexToDecimal = (x: string) => ecVal.keyFromPrivate(x, 'hex').getPrivate().toString(10);

    // ec.recoverPubKey returns Point. encode('hex', true) will convert it to hex string compact key
    // @see node_modules/elliptic/lib/elliptic/curve/base.js:302 BasePoint.prototype.encode
    return ecVal.recoverPubKey(hexToDecimal(msg), sig, sig.recoveryParam).encode('hex', true);
  }

  /**
   *
   * @param {Buffer} msg
   * @param {Buffer} signature
   * @param {Point|String} key - public key (depends on encoding)
   * @param {String} enc - encoding of private key. possible value = 'hex', else it's treated as Buffer
   * @return {boolean}
   */
  // @ts-ignore
  static verify(msg, signature, key, enc = 'hex') {
    return ecVal.verify(msg, CryptoLib.signatureFromBuffer(signature), key, enc);
  }

  /**
   *
   * @param {String | Buffer} publicKey - transform if needed as kyePair.getPublic(true, 'hex')
   * @param {Boolean} needBuffer - do we need address as Buffer or as String
   * @return {String | Buffer}
   */
  // @ts-ignore
  static getAddress(publicKey, needBuffer = false) {
    return needBuffer ? Buffer.from(CryptoLib.hash160(publicKey), 'hex') : CryptoLib.hash160(publicKey);
  }

  /**
   * WARNING! Modify here! if change address to something different than 160 bit (hash160)
   * @return {Buffer}
   */
  static getAddrContractCreation() {
    return Buffer.alloc(20, 0);
  }

  // @ts-ignore
  static ripemd160(buffer) {
    return cryptojs.RIPEMD160(buffer).toString();
  }

  // @ts-ignore
  static hash160(buffer) {
    return CryptoLib.ripemd160(CryptoLib.createHash(buffer));
  }

  /**
   *
   * @param {Buffer} buffer
   * @param {Number} length acceptably 224 | 256 | 384 | 512
   * @return {String} hex string!!
   */
  // @ts-ignore
  static sha3(buffer, length = 256) {
    switch (length) {
      case 224:
        return sha3.sha3_224(buffer);
      case 256:
        return sha3.sha3_256(buffer);
      case 384:
        return sha3.sha3_384(buffer);
      case 512:
        return sha3.sha3_512(buffer);
      default:
        return sha3.sha3_256(buffer);
    }
  }

  // @ts-ignore
  static argon2(password, salt, hashLength = 32) {
    throw new Error('Not implemented yet');
  }

  // @ts-ignore
  static scrypt(password, salt, hashLength = 32, options): Promise<string> {
    // tslint:disable-next-line:prefer-const
    let {N, r, p} = options;
    if (!r) {
      r = 8;
    }
    return new Promise((resolve, reject) => {
      const passwordBytes = this.getPassword(password);
      const saltBytes = this.looseArrayify(salt);
      // @ts-ignore
      scrypt(passwordBytes, saltBytes, N, r, p, hashLength, (error, progress, key) => {
        if (error) {
          reject(error);
        } else if (key) {
          resolve(cryptojs.enc.Hex.parse(bytes.hexlify(key).substring(2)));
        }
      });
    });
  }

  // @ts-ignore
  static pbkdf2(password, salt, hashLength = 32, options) {
    // tslint:disable-next-line:no-shadowed-variable
    const {iterations} = options;
    salt = !salt || cryptojs.enc.Hex.parse(salt);
    return cryptojs.PBKDF2(password, salt, {
      keySize: hashLength,
      iterations: iterations
    });
  }

  // @ts-ignore
  static randomBytes(size) {
    // phantomjs needs to throw
    const MAX_BYTES = 65536;
    if (size > MAX_BYTES) {
      throw new RangeError('requested too many random bytes');
    }

    // tslint:disable-next-line:no-shadowed-variable
    const bytes = Buffer.allocUnsafe(size);

    if (size > 0) {  // getRandomValues fails on IE if size == 0
      if (size > MAX_BYTES) { // this is the max bytes crypto.getRandomValues
        // can do at once see https://developer.mozilla.org/en-US/docs/Web/API/window.crypto.getRandomValues
        for (let generated = 0; generated < size; generated += MAX_BYTES) {
          // buffer.slice automatically checks if the end is past the end of
          // the buffer so we don't have to here
          window.crypto.getRandomValues(bytes.slice(generated, generated + MAX_BYTES));
        }
      } else {
        window.crypto.getRandomValues(bytes);
      }
    }

    return bytes;
  }

  // @ts-ignore
  static async encrypt(msg, pass, keyAlgo = 'scrypt') {
    const saltBytes = this.randomBytes(LENGTH);
    const {key, options} = await this.createKey(keyAlgo, pass, saltBytes, null);
    const ivBytes = this.randomBytes(LENGTH);
    const iv = cryptojs.enc.Hex.parse(bytes.hexlify(ivBytes).substring(2));
    const cipher = cryptojs.AES.encrypt(cryptojs.enc.Hex.parse(msg), key, {
      iv: iv,
      padding: cryptojs.pad.Pkcs7,
      mode: cryptojs.mode.CBC
    });
    return Promise.resolve({
      iv: iv.toString(),
      encrypted: cipher.ciphertext.toString(cryptojs.enc.Hex),
      salt: bytes.hexlify(saltBytes).substring(2),
      hashOptions: options,
      keyAlgo
    });
  }

  static async decrypt(transitMessage: string, password: string) {
    return DecryptorFactory.get(transitMessage, password).decrypt();
  }

  // @ts-ignore
  static async createKey(passwordHashFunction, password, salt, hashOptions) {
    let key;
    let options;
    switch (passwordHashFunction) {
      case 'sha3':
        key = this.sha3(password, 256);
        break;
      case 'pbkdf2':
        options = hashOptions || PBKDF2_OPTIONS;
        key = this.pbkdf2(password, salt, keySize / 32, options);
        break;
      case 'argon2':
        key = this.argon2(password, salt, 32);
        break;
      case 'scrypt':
        options = hashOptions || SCRYPT_OPTIONS;
        key = await this.scrypt(password, salt, 32, options);
        break;
      default:
        throw new Error(`Hash function ${passwordHashFunction} is unknown`);
        break;
    }
    return {key, options};
  }

  // @ts-ignore
  private static getPassword(password) {
    if (typeof (password) === 'string') {
      return utf8.toUtf8Bytes(password, utf8.UnicodeNormalizationForm.NFKC);
    }
    return bytes.arrayify(password);
  }

  // @ts-ignore
  private static looseArrayify(hexString) {
    if (typeof (hexString) === 'string' && hexString.substring(0, 2) !== '0x') {
      hexString = '0x' + hexString;
    }
    return bytes.arrayify(hexString);
  }
}

export class DecryptorFactory {
  static get(encryptedMessage: string, password: string): BaseDecryptor {
    if (encryptedMessage.includes('version')) {
      return new HexDecryptor(encryptedMessage, password);
    } else {
      return new Base64Decryptor(encryptedMessage, password);
    }
  }
}

export abstract class BaseDecryptor {
  protected encryptedMessage: string;
  protected password: string;

  protected constructor(encryptedMessage: string, password: string) {
    this.encryptedMessage = encryptedMessage;
    this.password = password;
  }

  abstract decrypt(): Promise<string>;

  // @ts-ignore
  protected aesDecrypt(cipherText, key, iv) {
    return cryptojs.AES.decrypt(cipherText, key, {
      iv: iv,
      padding: cryptojs.pad.Pkcs7,
      mode: cryptojs.mode.CBC
    });
  }
}

export class HexDecryptor extends BaseDecryptor {
  constructor(encryptedMessage: string, password: string) {
    super(encryptedMessage, password);
  }

  async decrypt(): Promise<string> {
    const {iv, encrypted, salt, hashOptions, keyAlgo} = JSON.parse(this.encryptedMessage);
    const options = hashOptions || SCRYPT_OPTIONS;
    const {key} = await CryptoLib.createKey(keyAlgo, this.password, salt, options);
    const cipherText = cryptojs.enc.Hex.parse(encrypted).toString(cryptojs.enc.Base64);
    const ivWords = cryptojs.enc.Hex.parse(iv);
    return Promise.resolve(this.aesDecrypt(cipherText, key, ivWords));
  }
}

export class Base64Decryptor extends BaseDecryptor {
  constructor(encryptedMessage: string, password: string) {
    super(encryptedMessage, password);
  }

  async decrypt(): Promise<string> {
    const salt = this.encryptedMessage.substr(0, 32);
    const iv = cryptojs.enc.Hex.parse(this.encryptedMessage.substr(32, 32));
    const encrypted = this.encryptedMessage.substring(64);
    const {key} = await CryptoLib.createKey('pbkdf2', this.password, salt, {iterations: 100});
    return Promise.resolve(this.aesDecrypt(encrypted, key, iv).toString(cryptojs.enc.Utf8));
  }
}
