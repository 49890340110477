import {Buffer} from 'buffer';

export class Utils {
  static prepareForStringifyObject(obj: any): any {
    if (!(obj instanceof Object)) {
      return obj;
    }

    if (Buffer.isBuffer(obj)) {
      return obj.toString('hex');
    }

    if (Array.isArray(obj)) {
      return obj.map(elem => this.prepareForStringifyObject(elem));
    }

    const resultObject = {};
    for (const key of Object.keys(obj)) {
      if (typeof obj[key] === 'function' || typeof obj[key] === 'undefined') {
        continue;
      }

      if (Buffer.isBuffer(obj[key])) {
        // @ts-ignore
        resultObject[key] = obj[key].toString('hex');
      } else if (Array.isArray(obj[key])) {
        // @ts-ignore
        resultObject[key] = this.prepareForStringifyObject(obj[key]);
      } else if (Number.isInteger(obj[key])) {
        // @ts-ignore
        resultObject[key] = parseInt(obj[key], 0);
      } else if (obj[key] instanceof Object) {
        // @ts-ignore
        resultObject[key] = this.prepareForStringifyObject(obj[key]);
      } else {
        // @ts-ignore
        resultObject[key] = obj[key];
      }
    }
    return resultObject;
  }
}


// export default {
//   assert: (condition, message) => {
//     if (!condition) {
//       message = message || 'Assertion failed';
//       if (typeof Error !== undefined) {
//         throw new Error(message);
//       }
//       throw message;
//     }
//   }
// };
