import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {Router} from '@angular/router'
import {CilSmartContractTransactionModel} from "../../components/transaction.model";
import {BehaviorSubject} from "rxjs";
import {environment} from "../../../environments/environment";
import {
  AdminTokenResult,
  ApiClient,
  IWalletListUnspentQueryResult, NotCompletedTokensResult,
  TransactionViewModel,
  WalletCoinType, WalletListUnspentQueryResult
} from "../../components/Api";
import {CilTransactionHelper} from "../../components/CilTransaction";
import {WalletDto} from "../../components/WalletDto";
import * as timers from "timers";
import {CilWalletDto} from "../../components/CilWalletDto";


@Component({
    template: `<div *ngIf="isOK !== null" id="response_data">
      {{isOK}}
    </div>
    `,
    selector: 'send-token'
  }
)
export class SendTokenComponent implements OnInit {

  data: NotCompletedTokensResult | null = null;
  wallet: WalletDto | null = null;
  unspents: WalletListUnspentQueryResult | null = null;


  id: string | null = '';
  isOK: boolean | null = null;
  cilSmartContractTransactionModel = new BehaviorSubject<CilSmartContractTransactionModel | null>(null);

  constructor(
    protected api: ApiClient,
    private _route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    console.log('send-token-1');

    this._route.paramMap.subscribe((params: ParamMap) =>  {
      this.id = params.get('id')

      if (this.id != null) {
        // request for token info
        this.api.GetNotCompletedTokens(this.id).subscribe((data) => {

          if (data !== null && data.length === 1) {
            this.data = data[0];
            console.log(this.data);

            if (this.data.id == this.id && this.data.stage == 4) { // check id and stage
              // token data receive
              // send token

              //receive unspents
              this.api.getWalletListUnspent("Ux" + environment.cilTokenProofAddress).subscribe( _unspent => {

                if (_unspent !== null && _unspent !== undefined) {
                  this.unspents = _unspent;

                  console.log(this.unspents);

                  //make wallet
                  try {
                    this.wallet = new CilWalletDto(environment.cilTokenProof, null);

                    console.log(this.wallet);

                    if (this.wallet.address === environment.cilTokenProofAddress) {

                      //the same address
                      const arrArguments = [
                        this.data!.symbol,
                        this.data!.userAddress,
                        this.data!.emissionAmount];

                      const cilTran = CilTransactionHelper.getCilSmartContractTran(
                        this.wallet,
                        this.data!.contractAddress!,
                        environment.cilTokenConsiliumId,
                        0,
                        this.unspents,
                        'transfer',
                        arrArguments);

                      const request = new TransactionViewModel({
                        transaction: cilTran.encode().toString('hex'),
                      });

                      this.api.sendTransactionSendToken(request).subscribe(() => {
                        //SUCCESS

                        this.isOK = true; //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1

                      }, (error) => {
                        this.isOK = false;
                      });

                    } else {
                      this.isOK = false;
                    }
                  } catch (e) {
                    this.isOK = false;
                  }

                } else {
                  this.isOK = false;
                }

              }, (error) => {
                this.isOK = false;
              });


            } else {
              this.isOK = false;
            }
          }
          else {
            this.isOK = false;
          }

        }, (error) => {
          this.isOK = false;
        });
      }
      else {
        //No id
        this.isOK = false;
      }
    });

    console.log('send-token-2');




  }



  protected readonly JSON = JSON;
}
