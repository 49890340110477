import {Buffer} from 'buffer';

export class Coins {

  _data: any;

  constructor(amount: any, receiverAddr: any) {
    this._data = {
      amount,
      receiverAddr: Buffer.isBuffer(receiverAddr) ? receiverAddr : Buffer.from(receiverAddr, 'hex')
    };
  }

  // @ts-ignore
  static createFromData({amount, receiverAddr}) {
    return new this(amount, receiverAddr);
  }

  getAmount() {
    return this._data.amount;
  }

  /**
   *
   * @return {Buffer} address
   */
  getReceiverAddr() {
    return this._data.receiverAddr;
  }

  /**
   *
   * @return {{amount: *, receiverAddr: *}|*}
   */
  getRawData() {
    return this._data;
  }

  /**
   *
   * @param {Coins} coin
   * @returns {boolean|*}
   */
  equals(coin: any) {
    return this.getAmount() === coin.getAmount() && this.getReceiverAddr().equals(coin.getReceiverAddr());
  }
}
