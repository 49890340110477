import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule} from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ApiModule} from "./api/api.module";

import {API_URL, API_KEY, ApiClient, ADMIN_KEY} from "./components/Api";
import {environment} from "../environments/environment";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ApiModule
  ],
  providers: [
    {
      provide: API_URL,
      useValue: environment.apiUrl
    },
    {
      provide: API_KEY,
      useValue: environment.apiKey
    },
    {
      provide: ADMIN_KEY,
      useValue: environment.adminKey
    },
    ApiClient
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
