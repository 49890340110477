import {Environment} from "./interface";

export const environment: Environment = {
  production: false,
  apiKey: 'dd804f42cfd04827ba9ba229667ec5b4',
  adminKey: 'FGHJFRTe67wfdt67342gRFIi76',
  apiUrl: 'https://fortoken-api.dev20021.ubikiri.com',
  cilTokenRequiredAmount: 150000,
  cilTokenContractAddress: '5560c0ee0c7ffdc4ccb033056fcc95c1974c3ed1',
  cilTokenSmartContractId: 1,
  cilTokenConsiliumId: 1,
  explorerUri: 'https://test-explorer.ubikiri.com',
  cilTokenRequiredProof: 350000,
  cilTokenProofAddress: '52fd856648e112e0816484f02464c5cab6ceefdc',
  cilTokenProof: '3f00292654ff1dde0bde8850b31356c1ff648693cfa2dec5c61901ba1922b9a6'
};
