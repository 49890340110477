import {Inject, Injectable, InjectionToken, Optional} from "@angular/core";
import {HttpClient, HttpHeaders, HttpResponse, HttpResponseBase} from "@angular/common/http";
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import {environment} from "../../environments/environment";

export const API_URL = new InjectionToken<string>('API_URL');
export const API_KEY = new InjectionToken<string>('API_KEY');
export const ADMIN_KEY = new InjectionToken<string>('ADMIN_KEY');

@Injectable({
  providedIn: 'root'
})
export class ApiClient {
  private http: HttpClient;
  private baseUrl: string;
  private apiKey: string;
  private adminKey: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient,
              @Optional() @Inject(API_URL) baseUrl?: string,
              @Optional() @Inject(API_KEY) apiKey?: string,
              @Optional() @Inject(ADMIN_KEY) adminKey?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "";
    this.apiKey = apiKey ? apiKey : "";
    this.adminKey = adminKey ? adminKey : "";
  }
  // ******************************************************** adminGetExchangeTokenInfo *****************************************************************************************************
  GetNotCompletedTokens(id: string): Observable<NotCompletedTokensResult[]> {
    let url_ = this.baseUrl + "/AdminFourTokens/get-not-completed-tokens?id=" + id;
    url_ = url_.replace(/[?&]$/, "");

    let options_ : any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.adminKey
      })
    };

    return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processGetNotCompletedTokens(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetNotCompletedTokens(response_ as any);
        } catch (e) {
          return _observableThrow(e) as any as Observable<NotCompletedTokensResult[]>;
        }
      } else
        return _observableThrow(response_) as any as Observable<NotCompletedTokensResult[]>;
    }));
  }

  protected processGetNotCompletedTokens(response: HttpResponseBase): Observable<NotCompletedTokensResult[]> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        if (Array.isArray(resultData200)) {
          result200 = [] as any;
          for (let item of resultData200)
            result200!.push(NotCompletedTokensResult.fromJS(item));
        }
        else {
          result200 = <any>null;
        }
        return _observableOf(result200);
      }));
    } else if (status === 400) {
      return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
        let result400: any = null;
        let resultData400 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result400 = ProblemDetails.fromJS(resultData400);
        return throwException("Bad Request", status, _responseText, _headers, result400);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf(null as any);
  }


  // ******************************************************** sendTransaction *****************************************************************************************************
  sendTransactionCreateToken(request: TransactionViewModel | null | undefined, token: AdminTokenResult): Observable<void> {
    let url_ = this.baseUrl + "/_4Tokens/cil-transaction";
    url_ = url_.replace(/[?&]$/, "");
    const data: any = {
      transaction: request!.transaction,
      userAddress: token.userAddress,
      symbol: token.symbol,
      name: token.name,
      description: token.description,
      decimals: token.decimals,
      contractAddress: token.contractAddress,
      emissionAmount: token.emissionAmount,
      emissionAddress: token.emissionAddress,
      logo: token.logo,
      facebook: token.facebook,
      twitter: token.twitter,
      telegram: token.telegram,
      tags: token.tags,
    }
    const content_ = JSON.stringify(data);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": environment.apiKey
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processSendTransactionCreateToken(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processSendTransactionCreateToken(<any>response_);
        } catch (e) {
          return <Observable<void>><any>_observableThrow(e);
        }
      } else
        return <Observable<void>><any>_observableThrow(response_);
    }));
  }

  protected processSendTransactionCreateToken(response: HttpResponseBase): Observable<void> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return _observableOf<void>(<any>null);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status === 500) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result500: any = null;
        let resultData500 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result500 = MiddlewareExceptionWrapper.fromJS(resultData500);
        return throwException("Server Error", status, _responseText, _headers, result500);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<void>(<any>null);
  }

  sendTransactionSendToken(request: TransactionViewModel | null | undefined): Observable<void> {
    let url_ = this.baseUrl + "/_4Tokens/cil-simple-transaction";
    url_ = url_.replace(/[?&]$/, "");
    const data: any = {
      transaction: request!.transaction
    }
    const content_ = JSON.stringify(data);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": environment.apiKey
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processSendTransactionSendToken(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processSendTransactionSendToken(<any>response_);
        } catch (e) {
          return <Observable<void>><any>_observableThrow(e);
        }
      } else
        return <Observable<void>><any>_observableThrow(response_);
    }));
  }

  protected processSendTransactionSendToken(response: HttpResponseBase): Observable<void> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return _observableOf<void>(<any>null);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status === 500) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result500: any = null;
        let resultData500 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result500 = MiddlewareExceptionWrapper.fromJS(resultData500);
        return throwException("Server Error", status, _responseText, _headers, result500);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<void>(<any>null);
  }

  // ADMIN *************************************************************************************************************************************************************

  // ******************************************************** adminStart *****************************************************************************************************
  adminStart(id: string): Observable<AdminUserResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/start";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify('user');

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminStart(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminStart(<any>response_);
        } catch (e) {
          return <Observable<AdminUserResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<AdminUserResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminStart(response: HttpResponseBase): Observable<AdminUserResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = AdminUserResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<AdminUserResult>(<any>null);
  }

  // ******************************************************** adminGetTokens *****************************************************************************************************
  adminGetTokens(id: string, statuses: number[], sort: string, page: number, pageSize: number, filterValue: string): Observable<AdminTokenCountResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/get-tokens?";
    url_ += "sort=" + sort + "&";
    url_ += "order=" + String('order') + "&";
    url_ += "page=" + String(page) + "&";
    url_ += "pageSize=" + String(pageSize) + "&";
    url_ += "filterValue=" + String(filterValue) + "&";
    if (statuses !== undefined)
      for (const status of statuses) {
        url_ += "statuses=" + String(status) + "&";
      }
    url_ = url_.replace(/[?&]$/, "");

    let options_ : any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminGetTokens(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminGetTokens(<any>response_);
        } catch (e) {
          return <Observable<AdminTokenCountResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<AdminTokenCountResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminGetTokens(response: HttpResponseBase): Observable<AdminTokenCountResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = AdminTokenCountResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<AdminTokenCountResult>(<any>null);
  }

  // ******************************************************** adminGetBlackList *****************************************************************************************************
  adminGetBlackList(id: string, sort: string, page: number, pageSize: number, filterValue: string): Observable<AdminBlackListCountResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/get-black-list?";
    url_ += "sort=" + sort + "&";
    url_ += "order=" + String('order') + "&";
    url_ += "page=" + String(page) + "&";
    url_ += "pageSize=" + String(pageSize) + "&";
    url_ += "filterValue=" + String(filterValue) + "&";
    url_ = url_.replace(/[?&]$/, "");

    let options_ : any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminGetBlackList(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminGetBlackList(<any>response_);
        } catch (e) {
          return <Observable<AdminBlackListCountResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<AdminBlackListCountResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminGetBlackList(response: HttpResponseBase): Observable<AdminBlackListCountResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = AdminBlackListCountResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<AdminBlackListCountResult>(<any>null);
  }

  // ******************************************************** adminAddBlackList *****************************************************************************************************
  adminAddBlackList(name: string, id: string): Observable<BoolResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/add-black-list";
    url_ = url_.replace(/[?&]$/, "");
    const data: any = {
      name: name
    };
    const content_ = JSON.stringify(data);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminAddBlackList(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminAddBlackList(<any>response_);
        } catch (e) {
          return <Observable<BoolResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<BoolResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminAddBlackList(response: HttpResponseBase): Observable<BoolResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = BoolResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<BoolResult>(<any>null);
  }

  // ******************************************************** adminChangeBlackList *****************************************************************************************************
  adminChangeBlackList(blackListId: string, name: string, id: string): Observable<BoolResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/change-black-list";
    url_ = url_.replace(/[?&]$/, "");
    const postData: any = {
      id: blackListId,
      name: name
    };
    const content_ = JSON.stringify(postData);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminChangeBlackList(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminChangeBlackList(<any>response_);
        } catch (e) {
          return <Observable<BoolResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<BoolResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminChangeBlackList(response: HttpResponseBase): Observable<BoolResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = BoolResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<BoolResult>(<any>null);
  }

  // ******************************************************** adminDeleteBlackList *****************************************************************************************************
  adminDeleteBlackList(blackListId: string, id: string): Observable<BoolResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/delete-black-list";
    url_ = url_.replace(/[?&]$/, "");
    const data: any = {
      id: blackListId
    };
    const content_ = JSON.stringify(data);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminDeleteBlackList(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminDeleteBlackList(<any>response_);
        } catch (e) {
          return <Observable<BoolResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<BoolResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminDeleteBlackList(response: HttpResponseBase): Observable<BoolResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = BoolResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<BoolResult>(<any>null);
  }

  // ******************************************************** adminGetExchangeTokens *****************************************************************************************************
  adminGetExchangeTokens(id: string, statuses: number[], sort: string | undefined = undefined, page: number | undefined = undefined, pageSize: number | undefined = undefined, filterValue: string | undefined = undefined): Observable<AdminExchangeTokenCountResult> {
    let url_ = this.baseUrl + "/Exchange/get-tokens";
    url_ = url_.replace(/[?&]$/, "");
    const data: any = {
      statuses: statuses, //NULLABLE
      filterValue: filterValue, //NULLABLE

      order: 'order', //default=asc, desc //NULLABLE
      sort: sort, //default=currency, status, ticker //NULLABLE

      page: page, //NULLABLE
      pageSize: pageSize //NULLABLE
    };
    const content_ = JSON.stringify(data);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminGetExchangeTokens(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminGetExchangeTokens(<any>response_);
        } catch (e) {
          return <Observable<AdminExchangeTokenCountResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<AdminExchangeTokenCountResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminGetExchangeTokens(response: HttpResponseBase): Observable<AdminExchangeTokenCountResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = AdminExchangeTokenCountResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<AdminExchangeTokenCountResult>(<any>null);
  }


  // ******************************************************** adminGetExchangeTokenInfo *****************************************************************************************************
  adminGetExchangeToken(currency: string, id: string): Observable<AdminExchangeTokenResult> {
    let url_ = this.baseUrl + "/Exchange/get-token?currency=" + currency;
    url_ = url_.replace(/[?&]$/, "");

    let options_ : any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminGetFakeTokenInfo(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminGetFakeTokenInfo(<any>response_);
        } catch (e) {
          return <Observable<AdminExchangeTokenResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<AdminExchangeTokenResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminGetFakeTokenInfo(response: HttpResponseBase): Observable<AdminExchangeTokenResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = AdminExchangeTokenResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<AdminExchangeTokenResult>(<any>null);
  }

  // ******************************************************** adminAddToken *****************************************************************************************************
  adminAddToken(symbol: string, status: number, id: string): Observable<BoolResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/add-token";
    url_ = url_.replace(/[?&]$/, "");
    const data: any = {
      symbol: symbol,
      status: status
    };
    const content_ = JSON.stringify(data);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminAddToken(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminAddToken(<any>response_);
        } catch (e) {
          return <Observable<BoolResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<BoolResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminAddToken(response: HttpResponseBase): Observable<BoolResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = BoolResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<BoolResult>(<any>null);
  }

  // ******************************************************** adminChangeToken *****************************************************************************************************
  adminChangeToken(tokenId: string, data: any, id: string): Observable<BoolResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/change-token";
    url_ = url_.replace(/[?&]$/, "");
    const postData: any = {
      id: tokenId,
      ticker: data.ticker,
      name: data.name,
      description: data.description,
      decimals: data.decimals,
      contractAddress: data.contractAddress,
      status: data.status,
      logo: data.logo,
      facebook: data.facebook,
      twitter: data.twitter,
      telegram: data.telegram,
      tags: data.tags,
      sourceCode: data.sourceCode
    };
    const content_ = JSON.stringify(postData);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminChangeToken(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminChangeToken(<any>response_);
        } catch (e) {
          return <Observable<BoolResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<BoolResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminChangeToken(response: HttpResponseBase): Observable<BoolResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = BoolResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<BoolResult>(<any>null);
  }

  // ******************************************************** adminDeleteToken *****************************************************************************************************
  adminDeleteToken(tokenId: string, id: string): Observable<BoolResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/delete-token";
    url_ = url_.replace(/[?&]$/, "");
    const data: any = {
      id: tokenId
    };
    const content_ = JSON.stringify(data);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminDeleteToken(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminDeleteToken(<any>response_);
        } catch (e) {
          return <Observable<BoolResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<BoolResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminDeleteToken(response: HttpResponseBase): Observable<BoolResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = BoolResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<BoolResult>(<any>null);
  }

  // ******************************************************** adminGetMessages *****************************************************************************************************
  adminGetMessages(id: string, sort: string, page: number, pageSize: number, filterShowIsHide: boolean, filterOpenClose: number, filterValue: string, filterType: number): Observable<AdminMessageCountResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/get-messages?";
    url_ += "sort=" + sort + "&";
    url_ += "order=" + String('order') + "&";
    url_ += "page=" + String(page) + "&";
    url_ += "pageSize=" + String(pageSize) + "&";
    url_ += "filterShowIsHide=" + String(filterShowIsHide) + "&";
    url_ += "filterOpenClose=" + String(filterOpenClose) + "&";
    url_ += "filterValue=" + String(filterValue) + "&";
    url_ += "filterType=" + String(filterType) + "&";
    url_ = url_.replace(/[?&]$/, "");

    let options_ : any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminGetMessages(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminGetMessages(<any>response_);
        } catch (e) {
          return <Observable<AdminMessageCountResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<AdminMessageCountResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminGetMessages(response: HttpResponseBase): Observable<AdminMessageCountResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = AdminMessageCountResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<AdminMessageCountResult>(<any>null);
  }

  // ******************************************************** adminChangeMessage *****************************************************************************************************
  adminChangeMessage(messageId: string, data: any, id: string): Observable<BoolResult> {
    let url_ = this.baseUrl + "/AdminFourTokens/change-message";
    url_ = url_.replace(/[?&]$/, "");
    const postData: any = {
      id: messageId,
      symbol: data.symbol,
      type: data.type,
      email: data.email,
      telegram: data.telegram,
      message: data.message,
      isClose: data.isClose,
      isHide: data.isHide
    };
    const content_ = JSON.stringify(postData);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processAdminChangeMessage(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processAdminChangeMessage(<any>response_);
        } catch (e) {
          return <Observable<BoolResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<BoolResult>><any>_observableThrow(response_);
    }));
  }

  protected processAdminChangeMessage(response: HttpResponseBase): Observable<BoolResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = BoolResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<BoolResult>(<any>null);
  }

  // 4 TOKENS *************************************************************************************************************************************************************

  // ******************************************************** sendStart *****************************************************************************************************
  sendStart(id: string): Observable<void> {
    let url_ = this.baseUrl + "/_4Tokens/cil-start";
    url_ = url_.replace(/[?&]$/, "");

    let options_ : any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processSendStart(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processSendStart(<any>response_);
        } catch (e) {
          return <Observable<void>><any>_observableThrow(e);
        }
      } else
        return <Observable<void>><any>_observableThrow(response_);
    }));
  }

  protected processSendStart(response: HttpResponseBase): Observable<void> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return _observableOf<void>(<any>null);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status === 500) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result500: any = null;
        let resultData500 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result500 = MiddlewareExceptionWrapper.fromJS(resultData500);
        return throwException("Server Error", status, _responseText, _headers, result500);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<void>(<any>null);
  }

  // ******************************************************** getCilTokenStatus *****************************************************************************************************
  getCilTokenStatus(symbol: string | null | undefined, id: string): Observable<CilTokenDataResult> {
    let url_ = this.baseUrl + "/_4Tokens/cil-token-data?";
    if (symbol !== undefined)
      url_ += "symbol=" + encodeURIComponent("" + symbol) + "&";
    url_ = url_.replace(/[?&]$/, "");

    let options_ : any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };


    return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processGetCilTokenStatus(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetCilTokenStatus(<any>response_);
        } catch (e) {
          return <Observable<CilTokenDataResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<CilTokenDataResult>><any>_observableThrow(response_);
    }));
  }

  protected processGetCilTokenStatus(response: HttpResponseBase): Observable<CilTokenDataResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = CilTokenDataResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status === 500) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("Server Error", status, _responseText, _headers);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<CilTokenDataResult>(<any>null);
  }


  // ******************************************************** getBalance *****************************************************************************************************
  getBalance(walletAddress: string | null | undefined, id: string): Observable<WalletBalanceQueryResult> {
    let url_ = this.baseUrl + "/_4Tokens/cil-balance?";
    if (walletAddress !== undefined)
      url_ += "walletAddress=" + encodeURIComponent("" + walletAddress) + "&";
    url_ = url_.replace(/[?&]$/, "");

    let options_ : any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processGetBalance(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetBalance(<any>response_);
        } catch (e) {
          return <Observable<WalletBalanceQueryResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<WalletBalanceQueryResult>><any>_observableThrow(response_);
    }));
  }

  protected processGetBalance(response: HttpResponseBase): Observable<WalletBalanceQueryResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = WalletBalanceQueryResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<WalletBalanceQueryResult>(<any>null);
  }

  // ******************************************************** getWalletListUnspent *****************************************************************************************************
  getWalletListUnspent(walletAddress: string | null | undefined): Observable<WalletListUnspentQueryResult> {
    let url_ = this.baseUrl + "/_4Tokens/cil-list-unspent?";
    if (walletAddress !== undefined)
      url_ += "walletAddress=" + encodeURIComponent("" + walletAddress) + "&";
    url_ = url_.replace(/[?&]$/, "");

    let options_ : any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey
      })
    };

    return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processGetWalletListUnspent(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetWalletListUnspent(<any>response_);
        } catch (e) {
          return <Observable<WalletListUnspentQueryResult>><any>_observableThrow(e);
        }
      } else
        return <Observable<WalletListUnspentQueryResult>><any>_observableThrow(response_);
    }));
  }

  protected processGetWalletListUnspent(response: HttpResponseBase): Observable<WalletListUnspentQueryResult> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = WalletListUnspentQueryResult.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<WalletListUnspentQueryResult>(<any>null);
  }

  // ******************************************************** sendMessage *****************************************************************************************************
  sendMessage(symbol: string, type: MessageType, email: string, telegram: string, message: string, id: string): Observable<void> {
    let url_ = this.baseUrl + "/_4Tokens/send-message";
    url_ = url_.replace(/[?&]$/, "");
    const data: any = {
      symbol: symbol,
      type: type,
      email: email,
      telegram: telegram,
      message: message
    }
    const content_ = JSON.stringify(data);

    let options_ : any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Expose-Headers": "Content-Length, X-JSON",
        "ApiKey": this.apiKey,
        "Id": id
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
      return this.processSendMessage(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processSendMessage(<any>response_);
        } catch (e) {
          return <Observable<void>><any>_observableThrow(e);
        }
      } else
        return <Observable<void>><any>_observableThrow(response_);
    }));
  }

  protected processSendMessage(response: HttpResponseBase): Observable<void> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
    if (status === 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return _observableOf<void>(<any>null);
      }));
    } else if (status === 404) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result404: any = null;
        let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result404 = ProblemDetails.fromJS(resultData404);
        return throwException("Not Found", status, _responseText, _headers, result404);
      }));
    } else if (status === 500) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result500: any = null;
        let resultData500 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result500 = MiddlewareExceptionWrapper.fromJS(resultData500);
        return throwException("Server Error", status, _responseText, _headers, result500);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<void>(<any>null);
  }

}



export interface IWalletListUnspentQueryResult {
  items?: CilWalletListUnspentItem[] | undefined;
}

export class CilWalletListUnspentItem implements ICilWalletListUnspentItem {
  amount?: number | undefined;
  hash?: string | undefined;
  nOut?: number | undefined;
  isStable?: boolean | undefined;

  constructor(data?: ICilWalletListUnspentItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.amount = _data["amount"];
      this.hash = _data["hash"];
      this.nOut = _data["nOut"];
      this.isStable = _data["isStable"];
    }
  }

  static fromJS(data: any): CilWalletListUnspentItem {
    data = typeof data === 'object' ? data : {};
    let result = new CilWalletListUnspentItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["amount"] = this.amount;
    data["hash"] = this.hash;
    data["nOut"] = this.nOut;
    data["isStable"] = this.isStable;
    return data;
  }
}

export interface ICilWalletListUnspentItem {
  amount?: number | undefined;
  hash?: string | undefined;
  nOut?: number | undefined;
  isStable?: boolean | undefined;
}

export class WalletQueryResult implements IWalletQueryResult {
  id?: number | undefined;
  coinType?: WalletQueryResultCoinType | undefined;
  name?: string | undefined;
  hashAddress?: string | undefined;
  isHide?: boolean | undefined;

  constructor(data?: IWalletQueryResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.coinType = _data["coinType"];
      this.name = _data["name"];
      this.hashAddress = _data["hashAddress"];
      this.isHide = _data["isHide"];
    }
  }

  static fromJS(data: any): WalletQueryResult {
    data = typeof data === 'object' ? data : {};
    let result = new WalletQueryResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["coinType"] = this.coinType;
    data["name"] = this.name;
    data["hashAddress"] = this.hashAddress;
    data["isHide"] = this.isHide;
    return data;
  }
}

export interface IWalletQueryResult {
  id?: number | undefined;
  coinType?: WalletQueryResultCoinType | undefined;
  name?: string | undefined;
  hashAddress?: string | undefined;
  isHide?: boolean | undefined;
}

export enum WalletQueryResultCoinType {
  Cil = "Cil",
  Eth = "Eth",
}

export class TokenQueryResult implements ITokenQueryResult {
  id?: number | undefined;
  symbol?: string | undefined;
  decimal?: number | undefined;
  name?: string | undefined;
  hashAddress?: string | undefined;
  isHide?: boolean | undefined;
  balance?: number | undefined;
  logo?: string | undefined;
  swapTokenSymbol?: string | undefined;

  constructor(data?: ITokenQueryResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.symbol = _data["symbol"];
      this.decimal = _data["decimal"];
      this.name = _data["name"];
      this.hashAddress = _data["hashAddress"];
      this.isHide = _data["isHide"];
      this.balance = _data["balance"];
      this.logo = _data["logo"];
      this.swapTokenSymbol = _data["swapTokenSymbol"];
    }
  }

  static fromJS(data: any): TokenQueryResult {
    data = typeof data === 'object' ? data : {};
    let result = new TokenQueryResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["symbol"] = this.symbol;
    data["decimal"] = this.decimal;
    data["name"] = this.name;
    data["hashAddress"] = this.hashAddress;
    data["isHide"] = this.isHide;
    data["balance"] = this.balance;
    data["logo"] = this.logo;
    data["swapTokenSymbol"] = this.swapTokenSymbol;
    return data;
  }
}

export interface ITokenQueryResult {
  id?: number | undefined;
  symbol?: string | undefined;
  decimal?: number | undefined;
  name?: string | undefined;
  hashAddress?: string | undefined;
  isHide?: boolean | undefined;
  balance?: number | undefined;
  logo?: string | undefined;
  swapTokenSymbol?: string | undefined;
}

export class MetaQueryResultOfTokenQueryResult implements IMetaQueryResultOfTokenQueryResult {
  meta?: Metadata | undefined;
  count?: number | undefined;
  data?: TokenQueryResult[] | undefined;

  constructor(data?: IMetaQueryResultOfTokenQueryResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.meta = _data["meta"] ? Metadata.fromJS(_data["meta"]) : <any>undefined;
      this.count = _data["count"];
      if (Array.isArray(_data["data"])) {
        this.data = [] as any;
        for (let item of _data["data"])
          this.data!.push(TokenQueryResult.fromJS(item));
      }
    }
  }

  static fromJS(data: any): MetaQueryResultOfTokenQueryResult {
    data = typeof data === 'object' ? data : {};
    let result = new MetaQueryResultOfTokenQueryResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["meta"] = this.meta ? this.meta.toJSON() : <any>undefined;
    data["count"] = this.count;
    if (Array.isArray(this.data)) {
      data["data"] = [];
      for (let item of this.data)
        data["data"].push(item.toJSON());
    }
    return data;
  }
}

export interface IMetaQueryResultOfTokenQueryResult {
  meta?: Metadata | undefined;
  count?: number | undefined;
  data?: TokenQueryResult[] | undefined;
}

export class Metadata implements IMetadata {
  page?: number | undefined;
  perPage?: number | undefined;
  totalItems?: number | undefined;

  constructor(data?: IMetadata) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.page = _data["page"];
      this.perPage = _data["perPage"];
      this.totalItems = _data["totalItems"];
    }
  }

  static fromJS(data: any): Metadata {
    data = typeof data === 'object' ? data : {};
    let result = new Metadata();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["page"] = this.page;
    data["perPage"] = this.perPage;
    data["totalItems"] = this.totalItems;
    return data;
  }
}

export interface IMetadata {
  page?: number | undefined;
  perPage?: number | undefined;
  totalItems?: number | undefined;
}

export class MultipleQueryResultOfWalletQueryResult implements IMultipleQueryResultOfWalletQueryResult {
  count?: number | undefined;
  data?: WalletQueryResult[] | undefined;

  constructor(data?: IMultipleQueryResultOfWalletQueryResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.count = _data["count"];
      if (Array.isArray(_data["data"])) {
        this.data = [] as any;
        for (let item of _data["data"])
          this.data!.push(WalletQueryResult.fromJS(item));
      }
    }
  }

  static fromJS(data: any): MultipleQueryResultOfWalletQueryResult {
    data = typeof data === 'object' ? data : {};
    let result = new MultipleQueryResultOfWalletQueryResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["count"] = this.count;
    if (Array.isArray(this.data)) {
      data["data"] = [];
      for (let item of this.data)
        data["data"].push(item.toJSON());
    }
    return data;
  }
}

export interface IMultipleQueryResultOfWalletQueryResult {
  count?: number | undefined;
  data?: WalletQueryResult[] | undefined;
}

export class ProblemDetails implements IProblemDetails {
  type?: string | undefined;
  title?: string | undefined;
  status?: number | undefined;
  detail?: string | undefined;
  instance?: string | undefined;

  constructor(data?: IProblemDetails) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.type = _data["type"];
      this.title = _data["title"];
      this.status = _data["status"];
      this.detail = _data["detail"];
      this.instance = _data["instance"];
    }
  }

  static fromJS(data: any): ProblemDetails {
    data = typeof data === 'object' ? data : {};
    let result = new ProblemDetails();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["type"] = this.type;
    data["title"] = this.title;
    data["status"] = this.status;
    data["detail"] = this.detail;
    data["instance"] = this.instance;
    return data;
  }
}

export interface IProblemDetails {
  type?: string | undefined;
  title?: string | undefined;
  status?: number | undefined;
  detail?: string | undefined;
  instance?: string | undefined;
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = event => {
        observer.next((<any>event.target).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}

export class SwaggerException extends Error {
  override message: string;
  status: number;
  response: string;
  headers: { [key: string]: any; };
  result: any;

  constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isSwaggerException = true;

  static isSwaggerException(obj: any): obj is SwaggerException {
    return obj.isSwaggerException === true;
  }
}

export class MiddlewareExceptionWrapper implements IMiddlewareExceptionWrapper {
  /** Messgae */
  readonly message?: string | undefined;

  constructor(data?: IMiddlewareExceptionWrapper) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      (<any>this).message = _data["message"];
    }
  }

  static fromJS(data: any): MiddlewareExceptionWrapper {
    data = typeof data === 'object' ? data : {};
    let result = new MiddlewareExceptionWrapper();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["message"] = this.message;
    return data;
  }
}

export class TransactionFeeQueryResult implements ITransactionFeeQueryResult {
  transactionFee?: TransactionFee[] | undefined;
  limit?: number | undefined;

  constructor(data?: ITransactionFeeQueryResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      if (Array.isArray(_data["transactionFee"])) {
        this.transactionFee = [] as any;
        for (let item of _data["transactionFee"])
          this.transactionFee!.push(TransactionFee.fromJS(item));
      }
      this.limit = _data["limit"];
    }
  }

  static fromJS(data: any): TransactionFeeQueryResult {
    data = typeof data === 'object' ? data : {};
    let result = new TransactionFeeQueryResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.transactionFee)) {
      data["transactionFee"] = [];
      for (let item of this.transactionFee)
        data["transactionFee"].push(item.toJSON());
    }
    data["limit"] = this.limit;
    return data;
  }
}

export interface ITransactionFeeQueryResult {
  transactionFee?: TransactionFee[] | undefined;
  limit?: number | undefined;
}

export class TransactionFee implements ITransactionFee {
  type?: TransactionFeeType | undefined;
  value?: string | undefined;
  coinValue?: number | undefined;

  constructor(data?: ITransactionFee) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.type = _data["type"];
      this.value = _data["value"];
      this.coinValue = _data["coinValue"];
    }
  }

  static fromJS(data: any): TransactionFee {
    data = typeof data === 'object' ? data : {};
    let result = new TransactionFee();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["type"] = this.type;
    data["value"] = this.value;
    data["coinValue"] = this.coinValue;
    return data;
  }
}

export interface ITransactionFee {
  type?: TransactionFeeType | undefined;
  value?: string | undefined;
  coinValue?: number | undefined;
}

export class WalletListUnspentQueryResult implements IWalletListUnspentQueryResult {
  readonly items?: CilWalletListUnspentItem[] | undefined;

  constructor(data?: IWalletListUnspentQueryResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      if (Array.isArray(_data["items"])) {
        (<any>this).items = [] as any;
        for (let item of _data["items"])
          (<any>this).items!.push(CilWalletListUnspentItem.fromJS(item));
      }
    }
  }

  static fromJS(data: any): WalletListUnspentQueryResult {
    data = typeof data === 'object' ? data : {};
    let result = new WalletListUnspentQueryResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data["items"] = [];
      for (let item of this.items)
        data["items"].push(item.toJSON());
    }
    return data;
  }
}

export interface IWalletListUnspentQueryResult {
  items?: CilWalletListUnspentItem[] | undefined;
}

export class WalletBalanceQueryResult implements IWalletBalanceQueryResult {
  readonly balance?: number | undefined;

  constructor(data?: IWalletBalanceQueryResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      (<any>this).balance = _data["balance"];
    }
  }

  static fromJS(data: any): WalletBalanceQueryResult {
    data = typeof data === 'object' ? data : {};
    let result = new WalletBalanceQueryResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["balance"] = this.balance;
    return data;
  }
}

export interface IWalletBalanceQueryResult {
  balance?: number | undefined;
}
export class TransactionViewModel implements ITransactionViewModel {
  /** Hash */
  transaction?: string | undefined;

  constructor(data?: ITransactionViewModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.transaction = _data["transaction"];
    }
  }

  static fromJS(data: any): TransactionViewModel {
    data = typeof data === 'object' ? data : {};
    let result = new TransactionViewModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["transaction"] = this.transaction;
    return data;
  }
}

export class CilTokenDataResult implements ICilTokenDataResult {

  isCreated: boolean = false;
  symbol: string | undefined;
  totalSupply: string | undefined;
  issuerName: string | undefined;
  goals: string | undefined;
  contractAddress: string | undefined;
  creator: string | undefined;
  createTxHash: string | undefined;
  holdersCount: number | undefined;
  transfersCount: number | undefined;
  frozen: boolean | undefined;
  decimals: number | undefined;

  constructor(data?: ICilTokenDataResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.isCreated = _data.isCreated;
      this.symbol = _data.symbol;
      this.totalSupply = _data.totalSupply;
      this.issuerName = _data.issuerName;
      this.goals = _data.goals;
      this.contractAddress = _data.contractAddress;
      this.creator = _data.creator;
      this.createTxHash = _data.createTxHash;
      this.holdersCount = _data.holdersCount;
      this.transfersCount = _data.transfersCount;
      this.frozen = _data.frozen;
      this.decimals = _data.decimals;
    }
  }

  static fromJS(data: any): CilTokenDataResult {
    data = typeof data === 'object' ? data : {};
    let result = new CilTokenDataResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["isCreated"] = this.isCreated;
    data["symbol"] = this.symbol;
    data["totalSupply"] = this.totalSupply;
    data["issuerName"] = this.issuerName;
    data["goals"] = this.goals;
    data["contractAddress"] = this.contractAddress;
    data["creator"] = this.creator;
    data["createTxHash"] = this.createTxHash;
    data["holdersCount"] = this.holdersCount;
    data["transfersCount"] = this.transfersCount;
    data["frozen"] = this.frozen;
    data["decimals"] = this.decimals;
    return data;
  }
}

export interface ICilTokenDataResult {
  isCreated: boolean;
  symbol: string | undefined;
  totalSupply: string | undefined;
  issuerName: string | undefined;
  goals: string | undefined;
  contractAddress: string | undefined;
  creator: string | undefined;
  createTxHash: string | undefined;
  holdersCount: number | undefined;
  transfersCount: number | undefined;
  frozen: boolean | undefined;
  decimals: number | undefined;
}

/** Transaction model */
export interface ITransactionViewModel {
  /** Hash */
  transaction?: string | undefined;
}

export enum TransactionFeeType {
  Slow = "Slow",
  Average = "Average",
  Fast = "Fast",
}

export enum WalletCoinType {
  Cil = "Cil",
  Eth = "Eth",
}

/** Unhandled excpetions wrapper */
export interface IMiddlewareExceptionWrapper {
  /** Messgae */
  message?: string | undefined;
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
  if (result !== null && result !== undefined)
    return _observableThrow(result);
  else
    return _observableThrow(new SwaggerException(message, status, response, headers, null));
}

// ADMIN ************************************************************************************************************************************************

export class AdminBlackListResult implements IAdminBlackListResult {

  id: string | undefined;
  name: string | undefined;

  constructor(data?: IAdminBlackListResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data.id;
      this.name = _data.name;
    }
  }

  static fromJS(data: any): AdminBlackListResult {
    data = typeof data === 'object' ? data : {};
    let result = new AdminBlackListResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["name"] = this.name;
    return data;
  }
}

export interface IAdminBlackListResult {
  id: string | undefined;
  name: string | undefined;
}

export class AdminBlackListCountResult implements IAdminBlackListCountResult {

  items: AdminBlackListResult[] | undefined;
  totalCount: number | undefined;

  constructor(data?: IAdminBlackListCountResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.items = _data.items;
      this.totalCount = _data.totalCount;
    }
  }

  static fromJS(data: any): AdminBlackListCountResult {
    data = typeof data === 'object' ? data : {};
    let result = new AdminBlackListCountResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["items"] = this.items;
    data["totalCount"] = this.totalCount;
    return data;
  }
}

export interface IAdminBlackListCountResult {
  items: AdminBlackListResult[] | undefined;
  totalCount: number | undefined;
}

export class AdminTokenResult implements IAdminTokenResult {

  id: string | undefined;
  symbol: string | undefined;
  ticker: string | undefined;
  name: string | undefined;
  description: string | undefined;
  decimals: number | undefined;
  contractAddress: string | undefined;
  emissionAmount: number | undefined;
  emissionAddress: string | undefined;
  status: AdminTokenStatus | undefined;
  logo: string | undefined;
  facebook: string | undefined;
  twitter: string | undefined;
  telegram: string | undefined;
  tags: string[] | undefined;
  sourceCode: string | undefined;

  userAddress: string | undefined;
  stage:  number | undefined;
  complete: boolean | undefined;

  constructor(data?: IAdminTokenResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data.id;
      this.symbol = _data.symbol;
      this.ticker = _data.ticker;
      this.name = _data.name;
      this.description = _data.description;
      this.decimals = _data.decimals;
      this.contractAddress = _data.contractAddress;
      this.emissionAmount = _data.emissionAmount;
      this.emissionAddress = _data.emissionAddress;
      this.status = _data.status;
      this.logo = _data.logo;
      this.facebook = _data.facebook;
      this.twitter = _data.twitter;
      this.telegram = _data.telegram;
      this.tags = _data.tags;
      this.sourceCode = _data.sourceCode;
      this.userAddress = _data.userAddress;
      this.stage = _data.stage;
      this.complete = _data.complete;
    }
  }

  static fromJS(data: any): AdminTokenResult {
    data = typeof data === 'object' ? data : {};
    let result = new AdminTokenResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["symbol"] = this.symbol;
    data["ticker"] = this.ticker;
    data["name"] = this.name;
    data["description"] = this.description;
    data["decimals"] = this.decimals;
    data["contractAddress"] = this.contractAddress;
    data["emissionAmount"] = this.emissionAmount;
    data["emissionAddress"] = this.emissionAddress;
    data["status"] = this.status;
    data["logo"] = this.logo;
    data["facebook"] = this.facebook;
    data["twitter"] = this.twitter;
    data["telegram"] = this.telegram;
    data["tags"] = this.tags;
    data["sourceCode"] = this.sourceCode;
    data["userAddress"] = this.userAddress;
    data["stage"] = this.stage;
    data["complete"] = this.complete;
    return data;
  }
}

export interface IAdminTokenResult {
  id: string | undefined;
  symbol: string | undefined;
  ticker: string | undefined;
  name: string | undefined;
  description: string | undefined;
  decimals: number | undefined;
  contractAddress: string | undefined;
  emissionAmount: number | undefined;
  emissionAddress: string | undefined;
  status: AdminTokenStatus | undefined;
  logo: string | undefined;
  facebook: string | undefined;
  twitter: string | undefined;
  telegram: string | undefined;
  tags: string[] | undefined;
  sourceCode: string | undefined;

  userAddress: string | undefined;
  stage:  number | undefined;
  complete: boolean | undefined;
}

export class AdminTokenCountResult implements IAdminTokenCountResult {

  items: AdminTokenResult[] | undefined;
  totalCount: number | undefined;

  constructor(data?: IAdminTokenCountResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.items = _data.items;
      this.totalCount = _data.totalCount;
    }
  }

  static fromJS(data: any): AdminTokenCountResult {
    data = typeof data === 'object' ? data : {};
    let result = new AdminTokenCountResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["items"] = this.items;
    data["totalCount"] = this.totalCount;
    return data;
  }
}

export interface IAdminTokenCountResult {
  items: AdminTokenResult[] | undefined;
  totalCount: number | undefined;
}


export class AdminExchangeTokenResult implements IAdminExchangeTokenResult {

  currency: string | undefined;
  ticker: string | undefined;
  name: string | undefined;
  description: string | undefined;
  decimals: number | undefined;
  contractAddress: string | undefined;
  status: AdminTokenStatus | undefined;
  logo: string | undefined |null;
  tags: string[] | undefined |null;
  community: AdminExchangeCommunity | undefined;
  sourceCode: string | undefined;

  constructor(data?: IAdminExchangeTokenResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.currency = _data.currency;
      this.ticker = _data.ticker;
      this.name = _data.name;
      this.description = _data.description;
      this.decimals = _data.decimals;
      this.contractAddress = _data.contractAddress;
      this.status = _data.status;
      this.logo = _data.logo;
      this.tags = _data.tags;
      this.community = _data.community;
      this.sourceCode = _data.sourceCode;
    }
  }

  static fromJS(data: any): AdminExchangeTokenResult {
    data = typeof data === 'object' ? data : {};
    let result = new AdminExchangeTokenResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["currency"] = this.currency;
    data["ticker"] = this.ticker;
    data["name"] = this.name;
    data["description"] = this.description;
    data["decimals"] = this.decimals;
    data["contractAddress"] = this.contractAddress;
    data["status"] = this.status;
    data["logo"] = this.logo;
    data["tags"] = this.tags;
    data["community"] = this.community;
    data["sourceCode"] = this.sourceCode;
    return data;
  }
}

export interface IAdminExchangeTokenResult {
  currency: string | undefined;
  ticker: string | undefined;
  name: string | undefined;
  description: string | undefined;
  decimals: number | undefined;
  contractAddress: string | undefined;
  status: AdminTokenStatus | undefined;
  logo: string | undefined |null;
  tags: string[] | undefined |null;
  community: AdminExchangeCommunity | undefined;
  sourceCode: string | undefined;
}

export class AdminExchangeTokenCountResult implements IAdminExchangeTokenCountResult {

  items: AdminExchangeTokenResult[] | undefined;
  totalCount: number | undefined;

  constructor(data?: IAdminExchangeTokenCountResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.items = _data.items;
      this.totalCount = _data.totalCount;
    }
  }

  static fromJS(data: any): AdminExchangeTokenCountResult {
    data = typeof data === 'object' ? data : {};
    let result = new AdminExchangeTokenCountResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["items"] = this.items;
    data["totalCount"] = this.totalCount;
    return data;
  }
}

export interface IAdminExchangeTokenCountResult {
  items: AdminExchangeTokenResult[] | undefined;
  totalCount: number | undefined;
}

export class AdminExchangeCommunity implements IAdminExchangeCommunity {

  facebook: string | undefined;
  twitter: string | undefined;
  telegram: string | undefined;

  constructor(data?: IAdminExchangeCommunity) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.facebook = _data.facebook;
      this.twitter = _data.twitter;
      this.telegram = _data.telegram;
    }
  }

  static fromJS(data: any): AdminExchangeCommunity {
    data = typeof data === 'object' ? data : {};
    let result = new AdminExchangeCommunity();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["facebook"] = this.facebook;
    data["twitter"] = this.twitter;
    data["telegram"] = this.telegram;
    return data;
  }
}

export interface IAdminExchangeCommunity {
  facebook: string | undefined;
  twitter: string | undefined;
  telegram: string | undefined;
}

export class AdminUserResult implements IAdminUserResult {

  isAuth: boolean | undefined;
  isAdmin: boolean | undefined;
  token: string | undefined;


  constructor(data?: IAdminUserResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.isAuth = _data.isAuth;
      this.isAdmin = _data.isAdmin;
      this.token = _data.token;
    }
  }

  static fromJS(data: any): AdminUserResult {
    data = typeof data === 'object' ? data : {};
    let result = new AdminUserResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["isAuth"] = this.isAuth;
    data["isAdmin"] = this.isAdmin;
    data["token"] = this.token;
    return data;
  }
}

export interface IAdminUserResult {
  isAuth: boolean | undefined;
  isAdmin: boolean | undefined;
  token: string | undefined;
}

export class BoolResult implements IBoolResult {

  isOk: boolean | undefined;

  constructor(data?: IBoolResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.isOk = _data.isOk;
    }
  }

  static fromJS(data: any): BoolResult {
    data = typeof data === 'object' ? data : {};
    let result = new BoolResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["isOk"] = this.isOk;
    return data;
  }
}

export interface IBoolResult {
  isOk: boolean | undefined;
}

export class AdminMessageResult implements IAdminMessageResult {

  id: string | undefined;
  createDate: Date | undefined;
  symbol: string | undefined;
  type: MessageType | undefined;
  email: string | undefined;
  telegram: string | undefined;
  message: string | undefined;
  isClose: boolean | undefined;
  closeDate: Date | undefined;

  constructor(data?: IAdminMessageResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data.id;
      this.createDate = _data.createDate;
      this.symbol = _data.symbol;
      this.type = _data.type;
      this.email = _data.email;
      this.telegram = _data.telegram;
      this.message = _data.message;
      this.isClose = _data.isClose;
      this.closeDate = _data.closeDate;
    }
  }

  static fromJS(data: any): AdminMessageResult {
    data = typeof data === 'object' ? data : {};
    let result = new AdminMessageResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["createDate"] = this.createDate;
    data["symbol"] = this.symbol;
    data["type"] = this.type;
    data["email"] = this.email;
    data["telegram"] = this.telegram;
    data["message"] = this.message;
    data["isClose"] = this.isClose;
    data["closeDate"] = this.closeDate;
    return data;
  }
}

export interface IAdminMessageResult {
  id: string | undefined;
  createDate: Date | undefined;
  symbol: string | undefined;
  type: MessageType | undefined;
  email: string | undefined;
  telegram: string | undefined;
  message: string | undefined;
  isClose: boolean | undefined;
  closeDate: Date | undefined;
}

export class AdminMessageCountResult implements IAdminMessageCountResult {

  items: AdminMessageResult[] | undefined;
  totalCount: number | undefined;

  constructor(data?: IAdminMessageCountResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.items = _data.items;
      this.totalCount = _data.totalCount;
    }
  }

  static fromJS(data: any): AdminMessageCountResult {
    data = typeof data === 'object' ? data : {};
    let result = new AdminMessageCountResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["items"] = this.items;
    data["totalCount"] = this.totalCount;
    return data;
  }
}

export interface IAdminMessageCountResult {
  items: AdminMessageResult[] | undefined;
  totalCount: number | undefined;
}



export enum AdminTokenStatus {
  Premoderate = 0,
  Normal = 1,
  Trust = 2,
  Fake = 3
}

export enum MessageType {
  Unknown = 0,
  Complaint = 1,
  Decision = 2
}

export class NotCompletedTokensResult implements INotCompletedTokensResult {

  id: string | undefined;
  symbol: string | undefined;
  ticker: string | undefined;
  name: string | undefined;
  description: string | undefined;
  decimals: number | undefined;
  contractAddress: string | undefined;
  emissionAmount: number | undefined;
  emissionAddress: string | undefined;
  status: AdminTokenStatus | undefined;
  logo: string | undefined;
  facebook: string | undefined;
  twitter: string | undefined;
  telegram: string | undefined;
  tags: string[] | undefined;
  sourceCode: string | undefined;
  userAddress: string | undefined;
  stage: number | undefined;
  complete: boolean | undefined;

  constructor(data?: INotCompletedTokensResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data.id;
      this.symbol = _data.symbol;
      this.ticker = _data.ticker;
      this.name = _data.name;
      this.description = _data.description;
      this.decimals = _data.decimals;
      this.contractAddress = _data.contractAddress;
      this.emissionAmount = _data.emissionAmount;
      this.emissionAddress = _data.emissionAddress;
      this.status = _data.status;
      this.logo = _data.logo;
      this.facebook = _data.facebook;
      this.twitter = _data.twitter;
      this.telegram = _data.telegram;
      this.tags = _data.tags;
      this.sourceCode = _data.sourceCode;
      this.userAddress = _data.userAddress;
      this.stage = _data.stage;
      this.complete = _data.complete;


    }
  }

  static fromJS(data: any): NotCompletedTokensResult {
    data = typeof data === 'object' ? data : {};
    let result = new NotCompletedTokensResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["symbol"] = this.symbol;
    data["ticker"] = this.ticker;
    data["name"] = this.name;
    data["description"] = this.description;
    data["decimals"] = this.decimals;
    data["contractAddress"] = this.contractAddress;
    data["emissionAmount"] = this.emissionAmount;
    data["emissionAddress"] = this.emissionAddress;
    data["status"] = this.status;
    data["logo"] = this.logo;
    data["facebook"] = this.facebook;
    data["twitter"] = this.twitter;
    data["telegram"] = this.telegram;
    data["tags"] = this.tags;
    data["sourceCode"] = this.sourceCode;
    data["userAddress"] = this.userAddress;
    data["stage"] = this.stage;
    data["complete"] = this.complete;
    return data;
  }
}

export interface INotCompletedTokensResult {
  id: string | undefined;
  symbol: string | undefined;
  ticker: string | undefined;
  name: string | undefined;
  description: string | undefined;
  decimals: number | undefined;
  contractAddress: string | undefined;
  emissionAmount: number | undefined;
  emissionAddress: string | undefined;
  status: AdminTokenStatus | undefined;
  logo: string | undefined;
  facebook: string | undefined;
  twitter: string | undefined;
  telegram: string | undefined;
  tags: string[] | undefined;
  sourceCode: string | undefined;
  userAddress: string | undefined;
  stage: number | undefined;
  complete: boolean | undefined;

}



