import * as protobuf from 'protobufjs';
import {Buffer} from 'buffer';
import structures from './structures';
import {Coins} from './coins';
import {CryptoLib} from './crypto';


const CURRENT_TX_VERSION = 1;
const MAX_BLOCK_SIZE = 1024 * 1024;
const root = protobuf.Root.fromJSON(structures.jsonData);
const transactionProto = root.lookup('Transaction');
const transactionPayloadProto = root.lookup('TransactionPayload');


export class Transaction {
  _data: any;

  constructor(data: any) {
    if (Buffer.isBuffer(data)) {
      if (data.length > MAX_BLOCK_SIZE) {
        throw new Error('Oversize transaction');
      }
      // @ts-ignore
      this._data = transactionProto.decode(data);
    } else if (typeof data === 'object') {
      // @ts-ignore
      const errMsg = transactionProto.verify(data);
      if (errMsg) {
        throw new Error(`Transaction: ${errMsg}`);
      }

      // @ts-ignore
      this._data = transactionProto.create(data);
    } else if (data === undefined) {
      this._data = {
        payload: {
          conciliumId: 0,
          ins: [],
          outs: []
        },
        claimProofs: []
      };
    } else {
      throw new Error('Contruct from Buffer|Object|Empty');
    }
    if (!this._data.payload.version) {
      this._data.payload.version = CURRENT_TX_VERSION;
    }
    if (this._data.payload.conciliumId === undefined) {
      throw new Error('Specify witness group, who will notarize this TX');
    }

    // fix fixed64 conversion to Long. see https://github.com/dcodeIO/ProtoBuf.js/
    // If a proper way to work with 64 bit values (uint64, int64 etc.) is required,
    // just install long.js alongside this library.
    // All 64 bit numbers will then be returned as a Long instance instead of a possibly
    // unsafe JavaScript number (see).

    for (const output of this._data.payload.outs) {
      if (typeof output.amount.toNumber === 'function') {
        output.amount = output.amount.toNumber();
      }
    }
  }

  get conciliumId() {
    return this._data.payload.conciliumId;
  }

  set conciliumId(conciliumId) {
    this._data.payload.conciliumId = conciliumId;
  }

  get rawData() {
    return this._data;
  }

  get inputs() {
    const checkPath = this._data &&
      this._data.payload &&
      this._data.payload.ins &&
      Array.isArray(this._data.payload.ins);
    return checkPath ? this._data.payload.ins : undefined;
  }

  get outputs() {
    const checkPath = this._data &&
      this._data.payload &&
      this._data.payload.outs &&
      Array.isArray(this._data.payload.outs);
    return checkPath ? this._data.payload.outs : undefined;
  }

  get claimProofs() {
    const checkPath = this._data &&
      this._data.claimProofs &&
      Array.isArray(this._data.claimProofs);
    return checkPath ? this._data.claimProofs : undefined;
  }

  /**
   *
   * @return {Array} utxos (Buffer!) this tx tries to spend
   */
  get utxos() {
    const inputs = this.inputs;
    if (!inputs) {
      throw new Error('Unexpected: empty inputs!');
    }

    // @ts-ignore
    return inputs.map(_in => _in.txHash);
  }

  static createCoinbase() {
    const coinbase = new this(undefined);
    coinbase.addInput(Buffer.alloc(32), 0);
    return coinbase;
  }

  // createContract
  /**
   *
   * @param {String} strCode
   * @param {Buffer | undefined} addrChangeReceiver
   * @returns {Transaction}
   */
  static createContract(strCode: any, addrChangeReceiver: any) {
    // typeforce(typeforce.String, strCode);

    const tx = new this(undefined);
    tx._data.payload.outs.push({
      receiverAddr: CryptoLib.getAddrContractCreation(),
      contractCode: strCode,
      addrChangeReceiver,
      amount: 0
    });
    return tx;
  }

  /**
   *
   * @param {String} strContractAddr
   * @param {Object} objInvokeCode {method, arrArguments}
   * @param {Number} amount - coins to send to contract address
   * @param {Address} addrChangeReceiver - to use as exec fee
   * @returns {Transaction}
   */
  static invokeContract(strContractAddr: any, objInvokeCode: any, amount: any, addrChangeReceiver: any) {
    // typeforce(typeforce.tuple(types.StrAddress, typeforce.Object, typeforce.Number), arguments);
    // typeforce(typeforce.oneOf(types.Address, types.Empty), addrChangeReceiver);

    if (addrChangeReceiver && !Buffer.isBuffer(addrChangeReceiver)) {
      addrChangeReceiver = Buffer.from(addrChangeReceiver, 'hex');
    }

    const tx = new this(undefined);
    tx._data.payload.outs.push({
      amount,
      receiverAddr: Buffer.from(strContractAddr, 'hex'),
      contractCode: JSON.stringify(objInvokeCode),
      addrChangeReceiver
    });

    return tx;
  }

  /**
   *
   * @return {Array} Coins
   */
  getOutCoins() {
    const outputs = this.outputs;
    if (!outputs) {
      throw new Error('Unexpected: empty outputs!');
    }

    // @ts-ignore
    return outputs.map(out => new Coins(out.amount, out.receiverAddr));
  }

  /**
   *
   * @param {Buffer | String} utxo - unspent tx output
   * @param {Number} index - index in tx
   */
  addInput(utxo: any, index: any) {
    // typeforce(typeforce.tuple(types.Hash256bit, 'Number'), arguments);
    if (typeof utxo === 'string') {
      utxo = Buffer.from(utxo, 'hex');
    }

    this._checkDone();
    this._data.payload.ins.push({txHash: utxo, nTxOutput: index});
  }

  /**
   *
   * @param {Number} amount - how much to transfer
   * @param {Buffer} addr - receiver
   */
  addReceiver(amount: any, addr: any) {
    // typeforce(typeforce.tuple('Number', types.Address), arguments);

    this._checkDone();
    this._data.payload.outs.push({amount, receiverAddr: Buffer.from(addr, 'hex')});
  }

  /**
   * Now we implement only SIGHASH_ALL
   * The rest is TODO: SIGHASH_SINGLE & SIGHASH_NONE
   *
   * @param {Number} idx - for SIGHASH_SINGLE (not used now)
   * @return {String} !!
   */
  hash() {
    return this.getHash();
  }

  /**
   * SIGHASH_ALL
   *
   * @return {String} !!
   */
  getHash() {
    // @ts-ignore
    return CryptoLib.createHash(transactionPayloadProto.encode(this._data.payload).finish());
  }

  /**
   * Is this transaction could be modified
   *
   * @private
   */
  _checkDone() {

    // it's only for SIGHASH_ALL, if implement other - change it!
    if (this.getTxSignature() || this._data.claimProofs.length) {
      throw new Error(
        'Tx is already signed, you can\'t modify it');
    }
  }

  /**
   * Add clamProofs (signature of hash(idx)) for input with idx
   *
   * @param {Number} idx - index of input to sign
   * @param {Buffer | String} key - private key
   * @param {String} enc -encoding of key
   */
  claim(idx: any, key: any, enc = 'hex') {
    // typeforce(typeforce.tuple('Number', types.PrivateKey), [idx, key]);

    if (idx > this._data.payload.ins.length) {
      throw new Error('Bad index: greater than inputs length');
    }

    const hash = this.hash();
    this._data.claimProofs[idx] = CryptoLib.sign(hash, key, enc);
  }

  /**
   * Used to prove ownership of contract
   *
   * @param {Buffer | String} key - private key
   * @param {String} enc -encoding of key
   */
  signForContract(key: any, enc = 'hex') {
    // typeforce(types.PrivateKey, key);

    const hash = this.getHash();
    this._data.txSignature = CryptoLib.sign(hash, key, enc);
  }

  getTxSignature() {
    return Buffer.isBuffer(this._data.txSignature) ||
    (Array.isArray(this._data.txSignature) && this._data.txSignature.length) ?
      this._data.txSignature : undefined;
  }

  getTxSignerAddress(needBuffer = false) {
    if (!this.getTxSignature()) {
      return undefined;
    }
    try {
      const pubKey = CryptoLib.recoverPubKey(this.getHash(), this.getTxSignature());
      return CryptoLib.getAddress(pubKey, needBuffer);
    } catch (e) {
      console.error(e);
    }
    return undefined;
  }

  /**
   *
   * @param {Transaction} txToCompare
   * @return {boolean}
   */
  equals(txToCompare: any) {
    return this.hash() === txToCompare.hash() &&
      Array.isArray(this.claimProofs) &&
      this.claimProofs.every((val, idx) => {
        return Buffer.from(val).equals(txToCompare.claimProofs[idx]);
      });
  }

  encode() {
    // @ts-ignore
    return Buffer.from(transactionProto.encode(this._data).finish());
  }

  /**
   * Check whether is this TX coinbase: only one input and all of zeroes
   *
   * @returns {boolean}
   */
  isCoinbase() {
    const inputs = this.inputs;
    return inputs && inputs.length === 1
      && inputs[0].txHash.equals(Buffer.alloc(32))
      && inputs[0].nTxOutput === 0;
  }

  isContractCreation() {
    const outCoins = this.getOutCoins();
    return outCoins[0].getReceiverAddr().equals(CryptoLib.getAddrContractCreation());
  }

  /**
   * Amount of coins to transfer with this TX
   *
   * @returns {*}
   */
  amountOut() {
    return this.outputs.reduce((accum: any, out: any) => accum + out.amount, 0);
  }

  getContractCode() {
    const contractOutput = this._getContractOutput();
    return contractOutput.contractCode;
  }

  getContractAddr() {
    const contractOutput = this._getContractOutput();
    return contractOutput.receiverAddr;
  }

  getContractChangeReceiver() {
    const contractOutput = this._getContractOutput();
    return contractOutput.addrChangeReceiver;
  }

  getContractSentAmount() {
    const contractOutput = this._getContractOutput();
    return contractOutput.amount;
  }

  _getContractOutput() {
    const outputs = this.outputs;
    return outputs[0];
  }

  verify() {

    // check inputs (not a coinbase & nTxOutput - non negative)
    const inputs = this.inputs;
    // @ts-ignore
    const insValid = inputs && inputs.length && inputs.every(input => {
      return !input.txHash.equals(Buffer.alloc(32)) &&
        input.nTxOutput >= 0;
    });

    // check outputs
    const outputs = this.outputs;
    // @ts-ignore
    const outsValid = outputs && outputs.every(output => {
      return output.contractCode || output.amount > 0;
    });
  }
}
